import React from 'react'
import '../sass/base/_reset.scss'
import './jobs.css'
import { pushClassname, pushBodyClassname } from '../lib/pushClassname'

// TODO: Move classnames to CSS module
const JobsListing = () => {
  React.useEffect(pushBodyClassname('default-cursor'), [])
  // React.useEffect(pushClassname('force-overflow-y-scroll', '.page-wrapper'), [])

  return (
    <div
      className="listing has-default-cursor"
    >
      <h1>
        Engineering Lead
      </h1>
      <h2>
        NYC | preferred on-site
      </h2>
      <h3>
        You'll use: Python • Django • gRPC • Terraform • AWS
      </h3>
      <h2>
        About Us
      </h2>
      <p>
        <em>Muze is a communication app that helps you express yourself.</em> Our freeform canvas and tools let you have total creative control with gifs, text, photos, drawings and more. You can chat with friends, or directly publish to the web to share your ideas.
      </p>
      
      <p>
        <em>Everyone is creative.</em> Muze wants to cultivate everyone’s inner creativity, so people can express themselves more authentically.
        Our small team recently closed a $5.1 million seed round from Sequoia. We’re looking to add an engineering leader to help set the company up with a strong foundation to grow into 2021!
      </p>
      <h2>
        What We Are Looking For
      </h2>
      <p className="nomargin">
        We’re looking for an engineering leader to establish a strong engineering culture and solid processes. We're a small team of eight people, eager to focus on scalable, secure growth this year.
      </p>
      <p className="smallmargin">
        You’ll work directly with everyone on the team, and have immense engineering and product influence.
      </p>
      <ul class="b">
      <li>5+ years of industry experience</li>
      <li>Leadership skills (or willingness to step into a new leadership role) to help the engineering team prioritize most urgent issues</li>
      <li>Comfortable setting up best engineering practices for longterm sustainability</li>
      <li>Strong “lead by example” mentality</li>
      <li>Passionate about the arts</li>
      <li>Experience with messaging applications at scale</li>
      </ul>
      <p className="cta">
        To apply, email <a href="mailto:jobs@muze.nyc" style={{whiteSpace: 'pre'}}>jobs@muze.nyc</a>
      </p>
    </div>
  )
}

export default JobsListing
